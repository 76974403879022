<template>
    
</template>

<script>
  export default {
    name: "blank",
    data() {
      return {
      }
    },
    created() {
      let APPid = process.env.VUE_APP_WX_APPID;
      let redirect_uri = process.env.VUE_APP_WX_REDIRECT_URL;
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+APPid+'&redirect_uri='+ encodeURIComponent(redirect_uri)+'&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
    }
  }
</script>

<style scoped>

</style>